<template>
  <div class="wl-use-wallet">
    <wallet-box-1></wallet-box-1>
    <wallet-box-2></wallet-box-2>
    <wallet-box-3></wallet-box-3>
  </div>
</template>
<script>
import WalletBox1 from "./wallet-box1.vue";
import WalletBox2 from "./wallet-box2.vue";
import WalletBox3 from "./wallet-box3.vue";
export default {
  components: { WalletBox1, WalletBox2, WalletBox3 },
  data() {
    return {};
  },
};
</script>
