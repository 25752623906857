<template>
  <div class="com-item">
    <!-- 有什么用 -->
    <div class="m-title4">{{ $t("use.walletBox2T") }}</div>
    <div class="ul">
      <div
        class="m-item3"
        v-for="(item, index) in $t('use.walletBox2List')"
        :key="index"
      >
        <div class="img">
          <img
            :src="
              require(`../../assets/images/v4/use/walletBox2${index + 1}.png`)
            "
            alt=""
          />
        </div>
        <div class="t">{{ item.title }}</div>
        <div class="link"></div>
        <div class="p">
          {{ item.text }}
        </div>
        <!-- <div class="more">
          <a href="javascript:;" @click="uti.goPath(item.url)">
            {{ item.ft }}
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-arrowRight"></use>
            </svg>
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  padding-top: (32 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  padding-bottom: (32 / 3.75vw);
  .ul {
    // margin-top: 100px;
    width: 100%;
    .m-item3 {
      // margin: 0 auto;
      min-height: (286.67 / 3.75vw);
      .t {
        margin-top: (0 / 3.75vw);
      }
    }
  }
}
</style>
