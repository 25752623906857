<template>
  <div class="com-item">
    <!-- PSC钱包 -->
    <div class="m-title4" id="wallet">
      {{ $t("use.walletBox3T") }}
    </div>
    <div class="ul">
      <div
        class="m-item1"
        v-for="(item, index) in $t('use.walletBox3List')"
        :key="index"
      >
        <div class="img">
          <img
            :src="
              require(`../../assets/images/v4/use/walletBox3${index + 1}.png`)
            "
            alt=""
          />
        </div>
        <div class="t">{{ item.title }}</div>
        <div class="link"></div>

        <div class="p">
          {{ item.text }}
        </div>
        <div class="more">
          <a
            href="javascript:;"
            @click="uti.goPath(store.link.use.walletBox2[index])"
          >
            {{ item.ft }}
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-arrowRight"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  // height: 854px;
  padding-top: (10 /3.75vw);
  // padding-bottom: 100px;
  // margin-bottom: 250px;
  box-sizing: border-box;
  // background-image: url("../../assets/images/v4/home/box8Bg.png");
  background-repeat: no-repeat;
  text-align: center;
  .ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 (14 / 3.75vw);
    justify-content: space-between;
    margin-top: (54 / 3.75vw);
    .m-item1 {
      .t {
        margin-top: 0;
      }
      .link {
        margin: (3 / 3.75vw) auto;
      }
    }
  }
}
</style>
