<template>
  <div class="com-item">
    <div class="m-title">
      <!-- 通向您的数字未来的钥匙 -->
      {{ $t("use.walletBox1T2") }}
    </div>
    <div class="m-p">
      <!-- 钱包能够访问您的资金和PSC应用。应当只有您才能访问您的钱包。 -->
      {{ $t("use.walletBox1P") }}
    </div>
    <a href="#wallet">
      <div class="m-but2">
        <!-- 获取钱包 -->
        {{ $t("use.walletBox1But") }}
      </div>
    </a>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  height: (357.33 / 3.75vw);
  background: url("../../assets/images/v4/use/walletBox1.png"),
    linear-gradient(0deg, #dae6f2, #edf4fc);
  background-size: (194 / 3.75vw) (194 / 3.75vw), 100% 100%;
  background-position: center center, right bottom;
  background-repeat: no-repeat;
  padding-top: (115 / 3.75vw);
  padding-bottom: (52 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  .m-p {
    margin-top: (22 / 3.75vw);
    padding: 0 (14 / 3.75vw);
  }
  .m-but2 {
    margin-top: (49 / 3.75vw);
  }
}
</style>
